import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import AdsVideos from "../AdsVideos";
import { a11yProps, TabPanel } from "..";
import PlayerAlertVideoList from "./PlayerAlertVideoList";

function PlayerAlertVideo({
  listOfMedia,
  setIsActive,
}: {
  listOfMedia: any[];
  setIsActive: any;
}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsActive(newValue === 0 ? true : false);
  };
  return (
    <Box>
      <Box>
        <Tabs value={value} onChange={handleChange} sx={{ px: 3 }}>
          <Tab label="Available" {...a11yProps(0)} />
          <Tab label="Trash" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <PlayerAlertVideoList listOfMedia={listOfMedia} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PlayerAlertVideoList listOfMedia={listOfMedia} />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default PlayerAlertVideo;
