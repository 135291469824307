import { Delete } from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Table,
} from "@mui/material";
import React from "react";

import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import moment from "moment";
import useConfig from "../../../../hooks/useConfig";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import HttpService from "../../../../hooks/Https-services";
import { toast } from "react-toastify";
import ImageDialogBox from "../../SliderSettings/ReorderTable/dialog";
const AdsTable = ({
  listOfAds,
  GetActiveAds,
  listOfActiveAd,
  GetInActiveAds,
}: {
  listOfAds: any[];
  listOfActiveAd?: any[];
  GetActiveAds: () => void;
  GetInActiveAds: () => void;
}) => {
  const configData = useConfig();
  const navigate = useNavigate();
  const handleUnpublish = async (id: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UnPublishAd.replace(":id", id),
        {}
      );
      toast.success("Ad unpublished successfully");
      GetActiveAds();
      GetInActiveAds();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const handlePublish = async (id: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.PublishAd.replace(":id", id),
        {}
      );
      toast.success("Ad published successfully");
      GetActiveAds();
      GetInActiveAds();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const handelDeleteAd = async (id: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.DeleteAd.replace(":id", id),
        {}
      );
      toast.success("Ad deleted successfully");
      GetActiveAds();
      GetInActiveAds();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Thumbnail</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell align="left">End Date</TableCell>
            <TableCell align="left">Channels</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listOfAds.map((row: any, index: any) => (
            <TableRow
              key={index + 1}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {" "}
              <TableCell align="left">
                {row?.VideoUrls?.map((el: any) => (
                  <ImageDialogBox src={el?.thumbnailUrl} />
                ))}
              </TableCell>
              <TableCell align="left">
                {" "}
                {row?.StartDateTime
                  ? moment(row?.StartDateTime).format("DD MMM YYYY hh:mm A")
                  : "Not Available"}
              </TableCell>
              <TableCell align="left">
                {row?.StartDateTime
                  ? moment(row?.EndDateTime).format("DD MMM YYYY hh:mm A")
                  : "Not Available"}
              </TableCell>
              <TableCell align="left">
                {row?.ChannelsDetails?.map((el: any) => el?.channel_name).join(
                  ","
                )}
              </TableCell>
              <TableCell align="right">
                <Box
                  sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}
                >
                  <button
                    onClick={() => {
                      if (row?.IsActive) {
                        handleUnpublish(row?.ID);
                      } else {
                        handlePublish(row?.ID);
                      }
                    }}
                    disabled={
                      !row?.IsActive && listOfActiveAd?.length ? true : false
                    }
                    style={{
                      cursor: "pointer",
                      width: "200px",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    {row?.IsActive ? "Move to Trash" : "Make Available"}
                  </button>{" "}
                  <button
                    onClick={() =>
                      navigate(PageRoutes.EditAds.replace(":id", row?.ID))
                    }
                    style={{
                      cursor: "pointer",
                      width: "50px",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    <EditIcon />
                  </button>
                  <button
                    onClick={() => handelDeleteAd(row?.ID)}
                    style={{
                      cursor: "pointer",
                      width: "50px",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    <Delete />
                  </button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default AdsTable;
