import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import useConfig from "../../../../../hooks/useConfig";
import HttpService from "../../../../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../../../../utils/constants";
import ScheduleIcon from "@mui/icons-material/Schedule";
const StatusBox = ({ data, handleRefresh }: any) => {
  const configData = useConfig();
  const navigate = useNavigate();
  const handleUnpublish = async (row: any) => {
    try {
      const payload = {
        game_id: row?.GameID,
        customer_id: row?.CustomerID,
      };
      const res = await HttpService.post(
        APIRoutes.UnPublishPlayerError.replace(":id", row?.ID),
        payload
      );

      handleRefresh();
    } catch (error) {}
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePublish = async (row: any) => {
    try {
      const payload = {
        message: row?.Message,
        game_id: row?.GameID,
        customer_id: row?.CustomerID,
        is_active: true,
        placement: row?.Placement,
        speed: row?.Speed,
        image: row?.Image,
        type: row?.Type,
        time: row?.Time,
        link: row?.Link,
        linking: row?.Linking,
        is_pause: row?.IsPause,
      };
      const res = await HttpService.post(
        APIRoutes.PublishPlayerError.replace(":id", row?.ID),
        payload
      );

      handleRefresh();
    } catch (error) {}
  };
  const handelDeleteError = async (row: any) => {
    const payload = {
      message: row?.Message,
      game_id: row?.GameID,
      customer_id: row?.CustomerID,
      is_active: false,
      open: false,
      placement: row?.Placement,
      speed: row?.Speed,
      image: row?.Image,
      type: row?.Type,
      link: row?.Link,
      linking: row?.Linking,
      is_pause: row?.IsPause,
    };
    try {
      const res = await HttpService.post(
        APIRoutes.DeleteErrorMessage.replace(":id", row?.ID),
        payload
      );
      handleRefresh();
    } catch (error) {}
  };
  return (
    <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-start" }}>
      <button
        onClick={() => {
          if (data?.IsActive) {
            handleUnpublish(data);
          } else {
            handlePublish(data);
          }
        }}
        style={{
          cursor: "pointer",
          width: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={configData.buttonCommonClass}
      >
        {!!data?.IsSchedule && <ScheduleIcon sx={{ color: "#7CFC00" }} />}
        {data?.IsActive ? "Move to Trash" : "Make Available"}
      </button>{" "}
      <button
        onClick={() =>
          navigate(PageRoutes.EditPlayerAlerts.replace(":id", data?.ID))
        }
        style={{
          cursor: "pointer",
          width: "50px",
        }}
        className={configData.buttonCommonClass}
      >
        <EditIcon />
      </button>
      <button
        onClick={() => handelDeleteError(data)}
        style={{
          cursor: "pointer",
          width: "50px",
        }}
        className={configData.buttonCommonClass}
      >
        <Delete />
      </button>
    </Box>
  );
};
export default StatusBox;
