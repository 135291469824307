import { useNavigate, useParams } from "react-router-dom";
import useConfig from "../../../../hooks/useConfig";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import {
  Form,
  Formik,
  FormikContextType,
  FormikHelpers,
  FormikValues,
  useFormik,
  useFormikContext,
} from "formik";
import HttpService from "../../../../hooks/Https-services";
import { Player } from "bitmovin-player";
import { VisuallyHiddenInput } from "../../SliderSettings/CreateSliderForm/SliderForm";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import SelectVideo from "./SelectVideo";

const CreateAds = () => {
  const navigate = useNavigate();
  const configData = useConfig();
  const { id }: any = useParams();
  const [initialValues, setIntialValue] = useState<any>({
    start_date: null,
    end_date: null,
    channels: [],
    files: [],
    videos: [],
  });
  interface ValidationContext {
    parent: {
      files: any[];
      videos: any[];
    };
  }

  const validationSchema = Yup.object().shape({
    end_date: Yup.string().required("End date is required"),
    channels: Yup.array()
      .min(1, "At least one channel is required")
      .required("Channels are required"),

    videos: Yup.array().test(
      "at-least-one-files-or-videos",
      "At least one file or video is required",
      function (this: ValidationContext, videos: any) {
        const { files } = this.parent;
        return videos.length > 0 || (files && files.length > 0);
      }
    ),
  });
  const [videoFiles, setVideoFiles] = useState<any[]>([]);
  const [channelOption, setChannelOption] = useState<any[]>([]);
  const [draggedItem, setDraggedItem] = useState<any>(null);

  // Handle dragging

  // Handle dropping to rearrange the array

  // Allow drop
  const handleDragOver = (e: any) => {
    e.preventDefault(); // Prevent default to allow dropping
  };

  const FetchChannels = useCallback(async () => {
    try {
      const result = await HttpService.getapi(
        APIRoutes.ChannelOption.replace(":id", configData.customer)
      );

      setChannelOption(result.data.data);
    } catch (error) {}
  }, []);
  useEffect(() => {
    FetchChannels();
  }, [FetchChannels]);

  const GetAdsDetailsByID = useCallback(async () => {
    if (id !== "0") {
      const res = await HttpService.get(
        APIRoutes.GetAdDetailsByID.replace(":id", id)
      );
      const data = res?.data?.data;
      setIntialValue({
        start_date: new Date(data?.StartDateTime),
        end_date: new Date(data?.EndDateTime),
        channels: data?.ChannelsDetails,
        videos: data?.VideoUrls,
        files: [],
      });
    } else {
      setIntialValue({
        ...initialValues,
      });
    }
  }, [id]);

  useEffect(() => {
    GetAdsDetailsByID();
  }, [GetAdsDetailsByID]);

  return (
    <Box sx={{ p: 4, maxWidth: 800, mx: "auto" }}>
      <button
        onClick={() => navigate(PageRoutes.Ads)}
        style={{
          cursor: "pointer",
          width: "100px",
          padding: "8px",
          margin: "10px 0px",
          fontSize: "14px",
        }}
        className={configData.buttonCommonClass}
      >
        Go Back
      </button>
      <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
        <Typography variant="subtitle1">
          {id == 0 ? "Create" : "Update"} Ad
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnMount={true}
        validationSchema={validationSchema}
        onSubmit={async (values: any) => {
          try {
            const formData = new FormData();
            formData.append("channels", JSON.stringify(values.channels));
            formData.append("start_time", JSON.stringify(values.start_date));
            formData.append("end_time", JSON.stringify(values.end_date));
            formData.append("videos", JSON.stringify(values.videos));

            if (id !== "0") {
              const response = await HttpService.postImage(
                APIRoutes.UpdateAd.replace(":id", id),
                formData
              );
              toast.success("Ad updated successfully");
            } else {
              const res = await HttpService.postImage(
                APIRoutes.CreateAd,
                formData
              );
              toast.success("Ad created successfully");
            }
            navigate(PageRoutes.Ads);
          } catch (error: any) {
            toast.error(error.response?.data?.message);
          }
        }}
      >
        {({
          handleSubmit,
          errors,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {/* <Box
              height={400}
              ref={playerContainerRef}
              className="player-container"
            ></Box> */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateRangePicker
                    format="dd/MM/yyyy hh:mm:ss aa"
                    disabled={isSubmitting}
                    ranges={[]}
                    block
                    onClean={() => {
                      setFieldValue("start_date", null);
                      setFieldValue("end_date", null);
                    }}
                    value={
                      !!values.start_date
                        ? [values?.start_date, values?.end_date]
                        : null
                    }
                    onChange={(value: any) => {
                      if (value) {
                        setFieldValue("start_date", new Date(value[0]));
                        setFieldValue("end_date", new Date(value[1]));
                      }
                    }}
                    placeholder="Select Date Range"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disabled={isSubmitting}
                    multiple
                    id="tags-outlined"
                    options={channelOption}
                    getOptionLabel={(option) => option.channel_name}
                    filterSelectedOptions
                    value={values?.channels || []}
                    isOptionEqualToValue={(option, value) =>
                      option.channel_id === value.channel_id
                    }
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "channels",
                        newValue.map((option) => option)
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Channels"
                        placeholder="Channels"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <SelectVideo />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack spacing={1}></Stack>
                  <Stack direction={"row"} gap={2} sx={{ mt: 2 }}>
                    {values?.videos?.map((el: any, index: number) => {
                      return (
                        <Box
                          onDragStart={() => setDraggedItem(index)}
                          onDragOver={handleDragOver}
                          onDrop={(e) => {
                            e.preventDefault();
                            console.log(el);
                            if (draggedItem === null) return;

                            const newItems = [...values?.videos];
                            const [removedItem] = newItems.splice(
                              draggedItem,
                              1
                            );
                            console.log(removedItem);
                            newItems.splice(index, 0, removedItem);

                            setFieldValue("videos", newItems);
                            setDraggedItem(null);
                          }}
                          draggable
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <video key={el?.videoUrl} width={"100%"} controls>
                            <source src={el?.videoUrl} type={"video/mp4"} />
                          </video>
                          <IconButton
                            onClick={() =>
                              setFieldValue(
                                "videos",
                                values.videos?.filter(
                                  (item: any, N: number) => N !== index
                                )
                              )
                            }
                            color="error"
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </Stack>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  width: 1,

                  justifyContent: "flex-end",
                }}
              >
                <Box sx={{ maxWidth: "200px" }}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{
                      ":hover": {
                        bgcolor: configData.primary,
                      },
                    }}
                    loading={isSubmitting}
                    disabled={!isValid}
                    onClick={() => handleSubmit()}
                    style={{
                      margin: "20px 0px",
                      cursor: "pointer",
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default CreateAds;
