import {
  Box,
  Chip,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import PageRoutes from "../../../../../utils/constants";
import ImageDialogBox from "../../../SliderSettings/ReorderTable/dialog";
import StatusBox from "../../../SliderSettings/ReorderTable/statusBox";
import StatusBoxMedia from "../../AddMedia/StatusBoxMedia";

const PlayerAlertVideoList = ({ listOfMedia }: { listOfMedia: any[] }) => {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Media
                </Typography>
              </TableCell>

              <TableCell>
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Description
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Status
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {listOfMedia?.map((row: any) => (
              <TableRow key={row.ID}>
                <TableCell component="th" scope="row">
                  <ImageDialogBox src={row.Thumbnail} />
                </TableCell>

                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    sx={{ fontStyle: "normal", textTransform: "none" }}
                  >
                    {row?.Description}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Chip label={row?.MediaStatus} />
                </TableCell>
                <TableCell style={{ width: 160 }} align="right">
                  <StatusBoxMedia data={row} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          component="div"
          colSpan={3}
          rowsPerPageOptions={[5, 10, 25]}
          count={allUsers.total_entries}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        /> */}
      </TableContainer>
    </Box>
  );
};
export default PlayerAlertVideoList;
