import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import SettingsIcon from "@mui/icons-material/Settings";
import useConfig from "../../hooks/useConfig";
import ChannelCard from "./ChannelCard";
import MatchCard from "./MatchCard";
import { useNavigate, useParams } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import HttpService from "../../hooks/Https-services";
import { PersonalChannelApi } from "./PersonalChannel.Api";
import { Add, AddBox, Delete } from "@mui/icons-material";
import DialogBox from "../../Common/Component/DialogBox";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../../store/reducers/commonReducer";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import BitMovingPlayerLinius from "./VideoCard/VideoPlayerForChannel";

const PersonalChannel = ({ subscriptionDetails }: any) => {
  const configData = useConfig();
  const videoRef: any = useRef();
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [liniusAccessToken, setLiniusAccessToken] = useState({} as any);
  const [currentVideoData, setCurrentVideoData] = useState({} as any);
  const [isSkeleton, setIsSkeleton] = React.useState(true);
  const [isSkeletonList, setIsSkeletonList] = React.useState(true);
  const [thubmnails, setThumbnails] = useState([] as any);
  const [currentTime, setCurrentTime] = useState(0);
  const [isDownloadAll, setIsDownloadAll] = useState(false);
  const playerRef: any = React.useRef(null);
  const [pageData, setPageData] = useState({} as any);
  const [page, setPage] = React.useState<any>(1);
  const [activeClip, setActiveClip] = useState("");
  const { id }: any = useParams();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [currentChannel, setCurrentChannel] = useState(id);
  const [currentChannelDetails, setCurrentChannelDetails] = useState({} as any);
  const userDetails = useCommonDetails();
  const [allDownloadVideo, setAllDownloadVideo] = useState([] as any);
  const [allChannels, setAllChannel] = useState([]);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );
  const isSmallScreen2 = useMediaQuery("(max-width: 769px)");
  const findCurrentClipTime = (el: any, index: any) => {
    let sum = 0;
    for (var i = 0; i < index; i++) {
      sum += videoList[i].duration;
    }
    return sum;
  };

  const GetVideo = async (channel_id: any) => {
    setIsSkeletonList(true);
    try {
      const res = await PersonalChannelApi.GetVideoList(
        APIRoutes.GetVideoList.replace(":page", page).replace(":id", channel_id)
      );

      if (res?.data?.data) {
        setVideoList(res?.data?.data?.content);
        setPageData(res?.data?.data);
        setCurrentVideoData(res?.data?.data?.content[0]);
        setActiveClip(res?.data?.data?.content[0]?.id);
        setIsSkeleton(false);
        setIsSkeletonList(false);
        // await GetAllThumbnais(res?.data?.data);
      }
      setIsSkeleton(false);
      setIsSkeletonList(false);
    } catch (error: any) {
      setIsSkeleton(false);
      setIsSkeletonList(false);
      toast.error(error.response.data.message);
    }
  };
  const GetAccessToken = async () => {
    try {
      const res = await PersonalChannelApi.GetLiniusAccessToken(
        APIRoutes.GetLiniusAccessToken
      );
      if (res?.data?.data) {
        localStorage.setItem(
          "persist:root",
          JSON.stringify({
            authSlice: JSON.stringify({
              accessToken: res?.data?.data.accessToken,
            }),
            userSlice: JSON.stringify({
              apiKey: configData.xApiKey,
            }),
          })
        );
        setLiniusAccessToken(res?.data?.data);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then((registration) => {
        registration?.active?.postMessage({
          accessToken: liniusAccessToken?.accessToken,
          apiKey: configData.xApiKey,
        });
      });
    }
  }, [liniusAccessToken, configData]);
  const GetAllChannel = async (isDeleted = false) => {
    setIsSkeleton(true);
    try {
      const res = await HttpService.get(APIRoutes.GetAllChannel);
      if (isDeleted) {
        setCurrentChannel(res.data.data[0]?.ID);
        navigate(
          PageRoutes.PersonalChannel.replace(":id", res.data.data[0]?.ID)
        );
      }
      if (id == 0) {
        GetVideo(res.data.data[0].ID);
        setCurrentChannel(res.data.data[0]?.ID);
        navigate(
          PageRoutes.PersonalChannel.replace(":id", res.data.data[0]?.ID)
        );
      }
      setAllChannel(res.data.data);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  const GetChannelByID = async (channel_id: any) => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetChannel.replace(":id", channel_id)
      );

      setCurrentChannelDetails(res.data.data);
    } catch (error) {}
  };
  const GetAllLinusDownloadVideo = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetAllLinusDownloadVideo);

      setAllDownloadVideo(res.data.data);
    } catch (error) {}
  };
  async function getEntries(data: any) {
    let finalData;
    try {
      const res = await HttpService.get(APIRoutes.GetAllChannel);
      if (res.data.data == null || res.data.data.length == 0) {
        finalData = { ...data, isChannelCreated: false };
      } else {
        finalData = { ...data, isChannelCreated: true };
      }
      dispatch(commonDetailSlice.actions.removecommonDetails());
      dispatch(commonDetailSlice.actions.setcommonDetails(finalData));
    } catch (error) {
      console.log(error);
    }
  }
  const DeleteChannel = async () => {
    try {
      const res = await HttpService.deleteData(
        APIRoutes.DeleteChannel.replace(":id", id)
      );

      if (allChannels?.length <= 1) {
        await getEntries(userDetails);

        navigate(
          PageRoutes.MyNAHLTV.replace(":id", "0").replace(":channel_id", "0")
        );
        handleClose();
      } else {
        GetAllChannel(true);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id != 0) {
      GetVideo(id);
      GetAllLinusDownloadVideo();
      GetChannelByID(id);
    } else {
      setIsSkeleton(false);
    }
    GetAccessToken();
  }, [page, currentChannel]);
  useEffect(() => {
    if (process.env.REACT_APP_FEATURE == "true") {
      GetAllChannel(false);
    } else {
      if (process.env.REACT_APP_FEATURE !== "true") {
        navigate({
          pathname: PageRoutes.PersonalChannel.replace(":id", "0"),
          search: "?tab_id=1",
        });
      }
    }
  }, []);

  return (
    <Box sx={{ px: 2, position: "relative" }}>
      {isSkeleton ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 10,
            width: 1,
          }}
        >
          <CircularProgress
            size={48}
            sx={{
              color: "#bf311a",
            }}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: "#edf2f7",
              py: "12px",
              // width: { xs: "100%", md: "95%" },
              // margin: "0 auto",
              px: { xs: 0, md: 1 },
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <OndemandVideoIcon
                sx={{ color: configData.primary }}
                fontSize="large"
              />
              <h3
                className="section-heading"
                style={{
                  marginLeft: "8px",
                  fontSize: isSmallScreen2 ? "19px" : "22px",
                }}
              >
                {" "}
                Personal Channel{" "}
              </h3>
            </Box>
          </Box>

          <Box sx={{ mt: 5 }} className="container-fluid">
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "start", ld: "center" },
                flexDirection: { xs: "column", ld: "row" },
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                {/* <Box
                component="img"
                sx={{ mr: 2, width: { xs: "50px", sm: "80px" } }}
                src="https://se-img.dcd-production.i.geniussports.com/ad0891559bec150468f8f438ee8c7813T1.jpg"
              /> */}
                <Typography
                  variant="h1"
                  sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
                >
                  {currentChannelDetails?.Configuration?.Name}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "start", sm: "center" },
                  gap: 1,
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Box>
                  <Select
                    size="small"
                    value={currentChannel}
                    onChange={(e) => {
                      setCurrentChannel(e.target.value);
                      navigate(
                        PageRoutes.PersonalChannel.replace(
                          ":id",
                          e.target.value
                        )
                      );
                    }}
                    sx={{ minWidth: "200px" }}
                  >
                    {allChannels?.map((el: any) => (
                      <MenuItem value={el?.ID}>
                        {el?.Configuration?.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {!isSmallScreen2 ? (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        navigate(
                          PageRoutes.MyNAHLTV.replace(":id", "1").replace(
                            ":channel_id",
                            "0"
                          )
                        )
                      }
                      size={isSmallScreen ? "small" : "large"}
                      sx={{ width: { xs: "110px", sm: "auto" } }}
                    >
                      <AddBox sx={{ mr: 1, fontWeight: 800 }} />

                      <Typography
                        variant="body2"
                        fontWeight="700"
                        sx={{
                          color: configData.primary,
                          fontStyle: "normal",
                          fontSize: { xs: "12px", sm: "14px" },
                        }}
                      >
                        ADD CHANNEL
                      </Typography>
                    </Button>
                  ) : (
                    <AddBox
                      fontSize="large"
                      sx={{ color: configData.primary }}
                    />
                  )}

                  {!isSmallScreen2 ? (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        navigate(
                          PageRoutes.MyNAHLTV.replace(":id", "1").replace(
                            ":channel_id",
                            id
                          )
                        )
                      }
                      size={isSmallScreen ? "small" : "large"}
                      sx={{ width: { xs: "110px", sm: "auto" } }}
                    >
                      <SettingsIcon sx={{ mr: 1 }} />

                      <Typography
                        variant="body2"
                        fontWeight="700"
                        sx={{
                          color: configData.primary,
                          fontStyle: "normal",
                          fontSize: { xs: "12px", sm: "14px" },
                        }}
                      >
                        EDIT CHANNEL
                      </Typography>
                    </Button>
                  ) : (
                    <IconButton
                      onClick={() =>
                        navigate(
                          PageRoutes.MyNAHLTV.replace(":id", "1").replace(
                            ":channel_id",
                            id
                          )
                        )
                      }
                    >
                      <SettingsIcon
                        fontSize="large"
                        sx={{ color: configData.primary }}
                      />
                    </IconButton>
                  )}
                  {!isSmallScreen2 ? (
                    <Button
                      variant="outlined"
                      onClick={() => handleClickOpen()}
                      size={isSmallScreen ? "small" : "large"}
                      sx={{ width: { xs: "110px", sm: "auto" } }}
                    >
                      <Delete sx={{ mr: 1 }} />

                      <Typography
                        variant="body2"
                        fontWeight="700"
                        sx={{
                          color: configData.primary,
                          fontStyle: "normal",
                          fontSize: { xs: "12px", sm: "14px" },
                        }}
                      >
                        DELETE CHANNEL
                      </Typography>
                    </Button>
                  ) : (
                    <IconButton onClick={() => handleClickOpen()}>
                      <Delete
                        fontSize="large"
                        sx={{ color: configData.primary }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
            <Divider sx={{ borderColor: "black", pt: 4 }} />
            {videoList?.length > 0 ? (
              <Grid sx={{ my: 2 }} container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Box sx={{ backgroundColor: configData.tertiary }}>
                    <Typography
                      variant="h2"
                      mb={1}
                      sx={{
                        color: "white",
                        padding: "5px",
                        px: 4,
                        borderRadius: "4px 4px 0px 0px",
                        boxShadow: "0 .5rem 1rem rgba(0,0,0,0.15)",
                        background: `linear-gradient(110deg, ${configData.secondary} 50%, ${configData.primary} 50%)`,
                      }}
                    >
                      {/* <SquareIcon sx={{fontSize:"10px",m:"10px",color:configData.primary}}/> */}
                      Now Showing
                    </Typography>

                    <Box sx={{ mt: 1 }}>
                      <BitMovingPlayerLinius
                        accessToken={liniusAccessToken?.accessToken}
                        page={page}
                        pageData={pageData}
                        setActiveClip={setActiveClip}
                        setPage={setPage}
                        setCurrentTime={setCurrentTime}
                        playerRef={playerRef}
                        currentVideoData={currentVideoData}
                        setCurrentVideoData={setCurrentVideoData}
                        videoList={videoList}
                        videoUrl={""}
                        setRunning={undefined}
                        autoPlay={false}
                        setIsSeeking={undefined}
                        checked={false}
                        handleChangeIconAction={undefined}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} width="100%">
                  <Typography
                    variant="h2"
                    mb={1}
                    sx={{
                      color: "white",
                      padding: "5px",
                      px: 4,
                      borderRadius: "4px 4px 0px 0px",
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,0.15)",
                      background: `linear-gradient(110deg, ${configData.secondary} 50%, ${configData.primary} 50%)`,
                    }}
                  >
                    {/* <SquareIcon sx={{fontSize:"10px",m:"10px",color:configData.primary}}/> */}
                    Up Next
                  </Typography>

                  <Stack
                    sx={{
                      my: 1,
                      mx: "auto",
                      width: 1,
                      justifyContent: "center",
                    }}
                    spacing={2}
                  >
                    <Pagination
                      onChange={handleChange}
                      page={page}
                      sx={{
                        mb: 1,
                        mt: 1,
                        width: 1,
                        mx: "auto",
                        justifyContent: "center !important",
                      }}
                      count={pageData?.totalPages}
                    />
                  </Stack>

                  <Box
                    sx={{
                      overflowY: "auto",
                      maxHeight: { xs: "350px", lg: "790px" },
                      mb: 2,
                      mt: 0,
                      textAlign: "center",
                    }}
                  >
                    {isSkeletonList ? (
                      <Stack spacing={2} sx={{ px: 2 }}>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height={70}
                        />
                        <Skeleton
                          variant="rectangular"
                          height={70}
                          animation="wave"
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height={70}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height={70}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height={70}
                        />
                      </Stack>
                    ) : (
                      videoList?.map((el: any, index: any) => (
                        <Box
                          onClick={() => {
                            playerRef.current.seek(
                              findCurrentClipTime(el, index)
                            );
                          }}
                        >
                          <MatchCard
                            isDownloadAll={isDownloadAll}
                            setIsDownloadAll={setIsDownloadAll}
                            allDownloadVideo={allDownloadVideo}
                            subscriptionDetails={subscriptionDetails}
                            el={el}
                            active={activeClip == el?.id}
                            thubmnails={thubmnails[index]}
                          />
                        </Box>
                      ))
                    )}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <>
                <Typography
                  variant="h3"
                  mb={1}
                  sx={{
                    color: "#000",
                    padding: "5px",
                    px: 4,
                    borderRadius: "4px 4px 0px 0px",
                    textAlign: "center",
                    my: 10,
                  }}
                >
                  No data found please update your preference
                </Typography>
              </>
            )}
          </Box>
        </>
      )}

      <DialogBox
        open={open}
        handleClose={handleClose}
        buttonAction={() => handleClose()}
        buttonActionSecondary={() => DeleteChannel()}
        buttonText={"Cancel"}
        butttonTextSecondary={"Delete"}
        button
        content={
          "Are you sure you want to delete this channel?  This action cannot be undone."
        }
      />
    </Box>
  );
};

export default PersonalChannel;
